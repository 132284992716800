import { sendRequest } from '../util'

/**
 * Send contact email
 */
export async function sendContactEmail(
	from: string,
	body: string
): Promise<boolean> {
	return !!(await sendRequest('post', '/api/mail/contact', { from, body }))
		.error
}
