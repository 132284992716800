import { sendRequest } from '../../util'

/**
 * Redeem Access Code
 */
export async function redeemAccessCode(code: string): Promise<boolean> {
	// Make api request
	const result = await sendRequest('post', `/api/access-code/${code}/redeem`)

	// Return boolean
	return result.error ? false : true
}
