import { siteInfo } from './siteInfo'

export const mail: MailConfig = Object.freeze({
	contact: Object.freeze({
		to: 'zprisk@kalmbach.com',
		subject: `${siteInfo.title} Contact Form Submission`,
		template: 'none'
	})
})

interface MailConfigOptions {
	to?: string
	subject?: string
	template?: string
}

interface MailConfig {
	contact: MailConfigOptions
	[key: string]: MailConfigOptions
}
