import styled from 'styled-components'

//----- Util -----//

function getBorder(
	swatch: { base: string; contrast: string },
	inverted?: boolean,
	weight?: 'light' | 'normal' | 'heavy'
): string {
	const width = weight === 'heavy' ? '3px' : '1px'
	const style = weight === 'light' ? 'dashed' : 'solid'
	const color = inverted ? swatch.base : swatch.contrast

	return `${width} ${style} ${color}`
}

//----- Component -----//
export const HorizontalRule = styled.hr<{
	inverted?: boolean
	weight?: 'light' | 'normal' | 'heavy'
}>`
	margin: 1em 0;
	border: 0;
	border-top: ${(p) => getBorder(p.theme.color.primary, p.inverted, p.weight)};
`
