import { css } from 'styled-components'
import type { FlattenInterpolation, ThemeProps } from 'styled-components'
import { transparentize } from 'polished'
import type { DefaultTheme } from '~/themes'

/**
 * Returns a box-shadow property based on the supplied color string
 */
export const glow = (
	color: string
): FlattenInterpolation<ThemeProps<typeof DefaultTheme>> => css`
	box-shadow:
		0 0 10px 0px ${color},
		0 0 3px 0px ${(p) => transparentize(0.5, p.theme.color.primary.contrast)};
`
