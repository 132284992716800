import Axios from 'axios'

interface ApiResponse<T> {
	error: boolean
	status: number
	data?: T
}

export async function sendRequest<T, D = undefined>(
	method: 'get' | 'delete' | 'post' | 'put',
	url: string,
	data?: D
): Promise<ApiResponse<T>> {
	const request = await Axios.request({
		method,
		url,
		data,
		headers: {
			'Content-Type': 'application/json'
		}
	}).catch((err) => err)

	const error = request.isAxiosError

	const res = error ? request.response : request

	return {
		error,
		status: res ? res.status : 500,
		data: res ? res.data : undefined
	}
}
