import { ReactElement } from 'react'
import NextLink from 'next/link'

import { Anchor_Base } from './Anchor_Base'
import { AnchorPropsInternal } from './Anchor_types'

export const Anchor_Internal = (props: AnchorPropsInternal): ReactElement => {
	return (
		<NextLink href={props.to || ''} passHref legacyBehavior>
			{/* eslint-disable-next-line jsx-a11y/anchor-is-valid -- eslint fails to detect passed href */}
			<Anchor_Base
				onClick={props.onClick}
				color={props.color}
				aria-label={props['aria-label']}
				className={props.className}
				style={props.style}
			>
				{props.children}
			</Anchor_Base>
		</NextLink>
	)
}
