import { useContext } from 'react'
import {
	ThemeConsumer as TC,
	ThemeContext,
	ThemeProvider as TP
} from 'styled-components'

import { DefaultTheme } from '~/themes'

//----- Consumers -----//

export const useThemeContext = (): typeof DefaultTheme.theme =>
	useContext(ThemeContext)
export const ThemeConsumer = TC

//----- Provider -----//

export const ThemeProvider = TP
