import styled from 'styled-components'

export const PageSubtitle = styled.h2`
	margin: 0.55em 0;

	font-size: 0.88em;
	font-weight: 400;

	@media screen and (min-width: 768px) {
		font-size: 1em;
	}
	@media screen and (min-width: 992px) {
		font-size: 1.15em;
	}
`
