import React, { ReactElement, ReactNode } from 'react'
import styled from 'styled-components'

//----- Type Definitions -----//

interface HeadingProps {
	marginTop?: boolean
	marginBottom?: boolean
}

interface BlockDescriptorProps extends HeadingProps {
	children: ReactNode
}

//----- Styling -----//

const Heading = styled.div<HeadingProps>`
	position: relative;
	margin: 1.67em 0;
	${(p) => !p.marginTop && 'margin-top: 0;'}
	${(p) => !p.marginBottom && 'margin-bottom: 0;'}
	width: 100%;

	font-size: 0.75em;
	font-weight: ${(p) => p.theme.font.sans.weight.semiBold};
	line-height: 1;
	text-align: center;
	z-index: 1;

	&:before {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 50%;

		transform: translateY(-50%);
		border-bottom: 1px dashed ${(p) => p.theme.color.gray[38]};
		z-index: -1;
	}
`

const Inner = styled.span`
	padding: 0 16px;
	text-transform: uppercase;
	background-color: ${(p) => p.theme.color.primary.base};
	pointer-events: none;
`

//----- Component -----//

export const BlockDescriptor = (props: BlockDescriptorProps): ReactElement => (
	<Heading
		marginTop={typeof props.marginTop === 'boolean' && props.marginTop}
		marginBottom={typeof props.marginBottom === 'boolean' && props.marginBottom}
	>
		<Inner>{props.children}</Inner>
	</Heading>
)
