import styled from 'styled-components'

const containerPadding = 32

interface ContainerProps {
	spaceBefore?: number
	spaceAfter?: number
	fullWidth?: boolean
}

export const Container = styled.div<ContainerProps>`
	margin: ${(p) => p.spaceBefore || 0}px auto ${(p) => p.spaceAfter || 0}px;
	padding: 0 ${containerPadding}px;
	${(p) => !p.fullWidth && `width: calc(64rem + ${containerPadding}px)`};
	max-width: 100%;
`
