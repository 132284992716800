export const analytics = Object.freeze({
	DIMENSION: Object.freeze({
		CATEGORY: 'dimension5',
		LOGIN_STATUS: 'dimension6',
		USER_ID: 'userId'
	}),
	LOGIN_STATUS: Object.freeze({
		ANONYMOUS: 0,
		REGISTERED: 1,
		SUBSCRIBED: 2
	}),
	EVENT: Object.freeze({
		ARCHIVE_READ: Object.freeze({
			category: 'Archive',
			action: 'Read Issue'
		}),
		ARCHIVE_DOWNLOAD: Object.freeze({
			category: 'Archive',
			action: 'Download Issue'
		}),
		CONTENT_SCROLL_PERCENT: Object.freeze({
			category: 'Content Scroll Percent',
			action: undefined // Use Scroll Percent when triggering event
		}),
		METER_LIMIT_REDUCED: Object.freeze({
			category: 'Metered Access: Limit Reduced',
			action: undefined // Use Views Remaining when triggering event
		}),
		NEWS_FORM_CONVERSION: Object.freeze({
			category: 'Free Subscriber Signup',
			action: 'Newsletter Submission'
		}),
		NEWS_PAYWALL_CONVERSION: Object.freeze({
			category: 'Free Subscriber Signup',
			action: 'Paywall Conversion'
		}),
		PAID_LINK_CONVERSION: Object.freeze({
			category: 'Paid Subscriber Click',
			action: 'Subscription Click'
		}),
		PAID_PAYWALL_CONVERSION: Object.freeze({
			category: 'Paid Subscriber Click',
			action: 'Paywall Conversion'
		})
	})
})
