import { sendRequest } from '../../util'
import { UserStateData } from '~/contexts'

/**
 * User Data
 */
export async function getUser(): Promise<false | UserStateData> {
	const result = await sendRequest<UserStateData>('get', '/api/user')

	return result.error || !result.data ? false : result.data
}
