export const richText = Object.freeze({
	// Insert Insticator after this paragraph position; set to -1 to disable
	insticatorPosition: 3 as number,

	//----- Constants -----//
	BLOCKS: Object.freeze({
		DOCUMENT: 'document',
		PARAGRAPH: 'paragraph',
		HEADING_1: 'heading-1',
		HEADING_2: 'heading-2',
		HEADING_3: 'heading-3',
		HEADING_4: 'heading-4',
		HEADING_5: 'heading-5',
		HEADING_6: 'heading-6',
		OL_LIST: 'ordered-list',
		UL_LIST: 'unordered-list',
		LIST_ITEM: 'list-item',
		HR: 'hr',
		QUOTE: 'blockquote',
		EMBEDDED_ENTRY: 'embedded-entry-block',
		EMBEDDED_ASSET: 'embedded-asset-block'
	}),
	CUSTOM: Object.freeze({
		INSTICATOR: 'insticator'
	}),
	INLINES: Object.freeze({
		HYPERLINK: 'hyperlink',
		ENTRY_HYPERLINK: 'entry-hyperlink',
		ASSET_HYPERLINK: 'asset-hyperlink',
		EMBEDDED_ENTRY: 'embedded-entry-inline',
		TEXT: 'text'
	}),
	MARKS: Object.freeze({
		BOLD: 'bold',
		ITALIC: 'italic',
		UNDERLINE: 'underline',
		CODE: 'code'
	})
})
