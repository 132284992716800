import { GetServerSidePropsContext } from 'next'
import { setCookie } from 'nookies'

import { SiteConfig } from '~/config'

const { sessionCookieName } = SiteConfig.app

/**
 * Sets the supplied cookie string as a client-side cookie.
 */
export function setClientCookie(
	rawCookieString?: string,
	ctx?: GetServerSidePropsContext
): void {
	if (!rawCookieString || !rawCookieString.length) return

	const cookieConfig: { [key: string]: string | Date | true } = {}
	let sessionId = ''

	// Break cookie string into property segments
	rawCookieString.split('; ').forEach((cookieProperty) => {
		// Break property into key/value pair
		const [rawKey, value = true] = cookieProperty.split('=')

		// Lowercase first char of key
		const key = rawKey.charAt(0).toLowerCase() + rawKey.slice(1)

		// Store the session ID or cookie property dependent on key
		if (key === sessionCookieName) {
			sessionId = typeof value === 'string' ? decodeURIComponent(value) : ''
		} else {
			cookieConfig[key] =
				key === 'expires' && typeof value === 'string' ? new Date(value) : value
		}
	})

	setCookie(ctx, sessionCookieName, sessionId, cookieConfig)
}
