import { createContext, ReactNode, ReactElement, useContext } from 'react'

export interface PaywallStateData {
	hasAccess: boolean
	isEntitled: boolean
	meterHasReduced: boolean
	viewsRemaining: number
}

// Note: Remove this comment when PaywallStateMethods is popluated
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PaywallStateMethods {}
interface PaywallState extends PaywallStateData, PaywallStateMethods {}

const PaywallContext = createContext<PaywallState>({
	hasAccess: false,
	isEntitled: false,
	meterHasReduced: false,
	viewsRemaining: 0
})

//----- Consumers -----//

export const usePaywallContext = (): PaywallState => useContext(PaywallContext)
export const PaywallConsumer = PaywallContext.Consumer

//----- Provider -----//

interface PaywallProps {
	paywallConfig: PaywallState
	children: ReactNode
}

export const PaywallProvider = (props: PaywallProps): ReactElement => (
	<PaywallContext.Provider value={props.paywallConfig}>
		{props.children}
	</PaywallContext.Provider>
)
