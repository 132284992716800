import styled from 'styled-components'

export const PageTitle = styled.h1<{
	smallMargins?: boolean
	retainCapitalization?: boolean
}>`
	margin: ${(p) => (p.smallMargins ? '0.33em 0 0' : '0.67em 0')};

	font-size: 1.5em;
	font-family: ${(p) => p.theme.font.title.family};
	font-weight: ${(p) => p.theme.font.title.weight.regular};
	line-height: 1.25;
	${(p) => p.retainCapitalization !== true && 'text-transform: capitalize;'}

	@media screen and (min-width: 768px) {
		font-size: 2.25em;
	}
	@media screen and (min-width: 992px) {
		font-size: 3em;
	}
`
