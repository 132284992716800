import React from 'react'
import styled from 'styled-components'

//----- Styling -----//

const BorderWrapper = styled.div`
	display: grid;
	grid-template-columns: [border] auto [content] 1fr;
	grid-gap: 8px;
	flex-grow: 0;
`

const Border = styled.div`
	width: 10px;
	background-image: linear-gradient(
		to right,
		black,
		black 45%,
		white 45.1%,
		white 55%,
		black 55.1%
	);
`

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 300px;
`

//----- Component -----//

export const SidebarContent: React.FC<{ children: React.ReactNode }> = (
	props
) => {
	return (
		<BorderWrapper>
			<Border />
			<ContentWrapper>{props.children}</ContentWrapper>
		</BorderWrapper>
	)
}
