import styled, { css } from 'styled-components'

export const InstareadFrame = styled.iframe<{ $loaded: boolean }>`
	${(p) =>
		p.$loaded
			? css`
					display: block;
					width: 100%;
					height: 100%;
					border: 0;
					overflow: hidden;
			  `
			: css`
					display: none;
					width: 0;
					height: 0;
			  `}
`
