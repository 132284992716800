import { useEffect } from 'react'
import { SiteConfig } from '~/config'

import { loadInsticator } from './util'

//----- Component -----//

export const Insticator: React.FC<{
	category?: keyof typeof SiteConfig.insticator
}> = ({ category }) => {
	const uuid =
		category && SiteConfig.insticator[category]
			? SiteConfig.insticator[category]
			: null

	useEffect(() => {
		const intervalId = loadInsticator(uuid)

		// Unset interval if this component unmounts
		return () => clearInterval(intervalId)
	}, [uuid])

	return !uuid ? null : (
		<div id="insticator-container" className={`embedid-${uuid}`}>
			<div id="div-insticator-ad-1"></div>
			<div id="insticator-embed"></div>
			<div id="div-insticator-ad-2"></div>
		</div>
	)
}
