import styled from 'styled-components'

//----- Types -----//

interface ListItemInlineProps {
	hideOnDesktop?: boolean
	hideOnMobile?: boolean
}

//----- Component -----//

export const ListItem_Inline = styled.li<ListItemInlineProps>`
	display: flex;
	align-items: center;

	${(p) =>
		p.hideOnDesktop &&
		`
		@media (min-width: ${p.theme.responsive.breakpoints.md}) {
			display: none;
		}
	`}

	${(p) =>
		p.hideOnMobile &&
		`
		@media (max-width: ${p.theme.responsive.breakpoints.md}) {
			display: none;
		}
	`}
`
