import { SiteConfig } from '~/config'

/**
 * Images coming from Contentful's "downloads" server are not mutable.
 * This means that they will download at their full, unscaled size which
 * can be gigantic in the case of an animated gif or other large file.
 * These large file sizes can significantly impact the cost of hosting.
 */
export function getValidAssetSrc(src: string): string {
	return src?.includes('downloads.ctfassets.net')
		? SiteConfig.siteInfo.placeholderImageUrl
		: src
}
