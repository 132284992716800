import styled from 'styled-components'

//----- Styling -----//

export const Anchor_Base = styled.a<{ color?: 'base' | 'contrast' | 'accent' }>`
	color: ${(p) =>
		p.color ? p.theme.color.primary[p.color] : p.theme.color.primary.contrast};
	text-decoration: none;

	&:hover {
		text-decoration: underline;
		cursor: pointer;
	}
`
