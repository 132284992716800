const MAX_ATTEMPTS = 25
const RETRY_DELAY = 100 // ms

/**
 * Waits for Insticator script to finish loading, then loads the relevant ads.
 *
 * Returns an interval ID value so that the interval can be cleared externally.
 */
export function loadInsticator(id: string | null): NodeJS.Timeout | undefined {
	if (!id) return undefined

	let attemptsCount = 0

	const intervalId = setInterval(() => {
		attemptsCount++

		// Prevent interval from running indefinitely if Insticator fails to load
		if (attemptsCount > MAX_ATTEMPTS) {
			clearInterval(intervalId)
			return
		}

		// Load ads once Insticator object is available
		if (window?.Insticator) {
			clearInterval(intervalId)
			window.Insticator.load('em', { id })
		}
	}, RETRY_DELAY)

	return intervalId
}
