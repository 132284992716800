import { ReactElement } from 'react'

import { Image } from '~/components/atoms'
import { getAssetQueryString, getValidAssetSrc } from '~/util/contentful/assets'

//----- Component -----//

interface ImageProps extends React.ComponentProps<typeof Image> {
	resizeMethod?: 'pad' | 'fill'
}

export const Image_Contentful = (props: ImageProps): ReactElement => {
	const { alt, src: rawSrc, resizeMethod, ...imgProps } = props

	const imageSrc = getValidAssetSrc(rawSrc)
	const queryString = getAssetQueryString(
		!imageSrc.endsWith('gif'), // Don't reformat gif images
		props.maxWidth,
		props.maxHeight,
		resizeMethod
	)

	const src = `${imageSrc}${queryString}`

	return <Image src={src} alt={alt} {...imgProps} />
}
