/**
 * Converts all images to progressive jpeg and resizes according to arguments.
 */
export function getAssetQueryString(
	changeFormat: boolean,
	maxWidth?: number,
	maxHeight?: number,
	resizeMethod: 'pad' | 'fill' = 'fill'
): string {
	const query: string[] = []

	if (changeFormat) {
		query.push('fm=jpg')
		query.push('fl=progressive')
	}

	if (maxWidth || maxHeight) {
		if (maxWidth) query.push(`w=${maxWidth}`)
		if (maxHeight) query.push(`h=${maxHeight}`)
		query.push(`fit=${resizeMethod}`)
	}

	return query.length ? `?${query.join('&')}` : ''
}
