import { ReactElement } from 'react'
import styled, { createGlobalStyle } from 'styled-components'

import { useAppContext } from '~/contexts'

//----- Styling -----//

const GlobalFixedBody = createGlobalStyle<{ isOpen: boolean }>`
	body {
		${(p) => p.isOpen && 'overflow: hidden;'}
	}
`

const Shade = styled.div<{ isOpen: boolean }>`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	background-color: ${(p) => p.theme.color.primary.contrast};
	opacity: 0;

	pointer-events: none;

	transition: opacity 200ms ease-in-out;
	z-index: ${(p) => p.theme.misc.zIndex.backdrop};

	${(p) =>
		p.isOpen &&
		`
		opacity: 0.7;
		pointer-events: auto;
	`}
`

//----- Component -----//

export const Backdrop = (): ReactElement | null => {
	const { backdropIsOpen, closeAllDialogs } = useAppContext()

	return (
		<>
			<GlobalFixedBody isOpen={backdropIsOpen} />
			<Shade
				isOpen={backdropIsOpen}
				onClick={closeAllDialogs}
				onKeyPress={closeAllDialogs}
				role="button"
				tabIndex={0}
				aria-label={'Dismiss Dialog'}
			/>
		</>
	)
}
