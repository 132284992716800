import { ChangeEvent, ReactElement } from 'react'
import styled from 'styled-components'

const arrowIcon = encodeURI(`
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
		<path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"/>
		<path d="M0 0h24v24H0z" fill="none"/>
	</svg>
`)

//----- Styling -----//

const SelectLabel = styled.span`
	margin-right: 0.5em;
`

const SelectField = styled.select`
	padding: 0.4em 1.5em 0.35em 0.45em;

	line-height: 1;

	appearance: none;
	border: 1px solid ${(p) => p.theme.color.primary.contrast};
	border-radius: 0;
	background: ${(p) => p.theme.color.primary.base}
		url('data:image/svg+xml;utf8,${arrowIcon}') no-repeat right center;
	background-size: 1.5em;
`

//----- Component -----//

type NumString = number | string

export const Select = (props: {
	label: string
	options: {
		name: NumString
		value: NumString
	}[]
	default?: NumString
	onChange?: (event: ChangeEvent<HTMLSelectElement>) => void
}): ReactElement => {
	return (
		<label>
			<SelectLabel>{props.label}</SelectLabel>
			{/* eslint-disable-next-line jsx-a11y/no-onchange -- rule has been deprecated */}
			<SelectField onChange={props.onChange} value={props.default}>
				{props.options.map((option) => (
					<option key={option.value} value={option.value}>
						{option.name}
					</option>
				))}
			</SelectField>
		</label>
	)
}
