import React from 'react'
import styled from 'styled-components'
import { Image_Contentful } from '../Image'

const IMAGE_SIZE = 50

//----- Styling -----//

const Avatar = styled.div`
	position: relative;
	display: inline-block;
	margin-right: 0.25em;
	height: ${IMAGE_SIZE}px;
	width: ${IMAGE_SIZE}px;

	img {
		position: absolute;
		bottom: -5px;
		border-radius: 50%;
		border: 1px solid black;
	}
`

//----- Component -----//

export const AuthorAvatar: React.FC<{ title?: string; url?: string }> = (
	props
) => {
	if (!props.url) return null

	return (
		<Avatar>
			<Image_Contentful
				src={props.url}
				alt={`${props.title} Avatar`}
				maxWidth={IMAGE_SIZE}
				maxHeight={IMAGE_SIZE}
			/>
		</Avatar>
	)
}
