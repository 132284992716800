import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga4'

import { SiteConfig } from '~/config'

const { DIMENSION, LOGIN_STATUS } = SiteConfig.analytics

//----- Types -----//

type AnalyticsEvent = {
	category: string
	action: string
	label?: string
	value?: number
	nonInteraction?: boolean
	transport?: 'xhr' | 'beacon' | 'image'
}

//----- Class -----//

export class Analytics {
	static instance: Analytics

	/**
	 * Initializes analytics modules.
	 */
	private initialize(): void {
		TagManager.initialize({ gtmId: SiteConfig.vendor.googleTagManager })
		ReactGA.initialize(SiteConfig.vendor.googleAnalytics, {
			testMode: process.env.DEBUG_ANALYTICS === 'true'
		})
	}

	/**
	 * Returns the singleton Analytics instance.
	 * Initializes Analytics if not run previously.
	 */
	static getInstance(): Analytics {
		if (!Analytics.instance) {
			Analytics.instance = new Analytics()
			Analytics.instance.initialize()
		}
		return Analytics.instance
	}

	/**
	 * Sets the given analytics dimension values. Calls from outside this class
	 * should use the predefined setter methods.
	 */
	private set(dimensions: Record<string, string | null>): Analytics {
		ReactGA.set(dimensions)
		return Analytics.instance
	}

	/**
	 * Sets the category dimension and returns the instance.
	 * @param categoryName Category name (not slug)
	 * @returns Analytics instance
	 */
	setCategory(categoryName: string | null): Analytics {
		Analytics.instance.set({ [DIMENSION.CATEGORY]: categoryName })
		return Analytics.instance
	}

	/**
	 * Sets the login status and user ID
	 * @param status User's current login status
	 * @param userId User's ID, if available
	 * @returns Analytics instance
	 */
	setLoginStatus(
		userId?: string,
		isLoggedIn?: boolean,
		hasMagazineSub?: boolean
	): Analytics {
		// Determine correct status based on user's subscriptions
		const status = hasMagazineSub
			? LOGIN_STATUS.SUBSCRIBED
			: isLoggedIn
			? LOGIN_STATUS.REGISTERED
			: LOGIN_STATUS.ANONYMOUS

		Analytics.instance.set({ [DIMENSION.LOGIN_STATUS]: `${status}` })

		// Also set userId
		Analytics.instance.setUserId(userId)

		return Analytics.instance
	}

	/**
	 * Sets the user's userId. Defaults to null if none is provided.
	 * @param userId User's ID, if available
	 * @returns Analytics instance
	 */
	setUserId(userId?: string): Analytics {
		Analytics.instance.set({ [DIMENSION.USER_ID]: userId || null })
		return Analytics.instance
	}

	//----- Event Helpers -----//

	/**
	 * Triggers an Archive Download analytics event
	 * @param issue Accessed Issue
	 * @returns Analytics instance
	 */
	archiveDownload(issue: string): Analytics {
		ReactGA.event({
			...SiteConfig.analytics.EVENT.ARCHIVE_DOWNLOAD,
			label: issue
		})

		return Analytics.instance
	}

	/**
	 * Triggers an Archive Read analytics event
	 * @param issue Accessed Issue
	 * @returns Analytics instance
	 */
	archiveRead(issue: string): Analytics {
		ReactGA.event({
			...SiteConfig.analytics.EVENT.ARCHIVE_READ,
			label: issue
		})

		return Analytics.instance
	}

	/**
	 * Triggers a page view analytics event
	 * @param path Current page's path
	 * @returns Analytics instance
	 */
	pageview(path: string): Analytics {
		ReactGA.send({
			hitType: 'pageview',
			page: path
		})
		return Analytics.instance
	}

	/**
	 * Triggers a modal view analytics event
	 * @param modalName Name of the modal
	 * @returns Analytics instance
	 * @deprecated
	 */
	modalview(modalName: string): Analytics {
		ReactGA.send({
			hitType: 'modalview',
			modal: modalName
		})
		return Analytics.instance
	}

	/**
	 * Triggers a page scroll analytics event
	 * @param path Content page path, including / prefix
	 * @param scrollPercent Percentage that the user has scrolled into the content
	 * @returns Analytics instance
	 */
	scrollEvent(path: string, scrollPercent: number): Analytics {
		ReactGA.event({
			...SiteConfig.analytics.EVENT.CONTENT_SCROLL_PERCENT,
			action: scrollPercent.toString(),
			label: path,
			nonInteraction: true
		})

		return Analytics.instance
	}

	/**
	 * Triggers a custom analytics event
	 * @param args An analytics event object
	 * @returns Analytics instance
	 */
	event(args: AnalyticsEvent): Analytics {
		ReactGA.event(args)
		return Analytics.instance
	}
}
