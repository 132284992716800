import { InvalidInput } from '../../types'
import { sendRequest } from '../../util'

/**
 * Newsletter Signup
 */
export async function newsletterSignup(
	email: string,
	optin: string[],
	source: string,
	birthdate?: string
): Promise<boolean | InvalidInput> {
	// Make api request
	const result = await sendRequest('post', '/api/newsletter/signup', {
		email,
		source,
		birthdate,
		optin
	})

	// Check for input validation errors
	if (result.error && result.data) {
		return result.data as InvalidInput
	}

	// Return boolean
	return result.error ? false : true
}
