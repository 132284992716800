import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'

import { color } from './color'
import { font } from './font'

export const global = {
	baseFont: font.sans,
	baseColor: color.primary
}

export const GlobalStyle = createGlobalStyle`
	${normalize}

	html {
		box-sizing: border-box;
	}

	*, *::before, *::after {
		box-sizing: inherit;
	}

	a {
		text-decoration: none;
	}

	body {
		margin: 0;

		font-family: ${global.baseFont.family};
		font-size: ${global.baseFont.size.normal}px;

		color: ${global.baseColor.contrast};
		background-color: ${global.baseColor.base};
	}
`
