import { ReactElement } from 'react'
import { isUrlInternal } from '~/util'

import { Anchor_Base } from './Anchor_Base'
import { Anchor_External } from './Anchor_External'
import { Anchor_Internal } from './Anchor_Internal'
import { AnchorPropsAny } from './Anchor_types'

export const Anchor = (props: AnchorPropsAny): ReactElement => {
	if (!props.to) return <Anchor_Base {...props} />

	const Component = isUrlInternal(props.to) ? Anchor_Internal : Anchor_External

	return <Component {...props} />
}
