import styled from 'styled-components'

//----- Styling -----//

type Breakpoints = {
	xs: string
	sm?: string
	md?: string
	lg?: string
	xl?: string
}

export const Whitespace = styled.div<Breakpoints>`
	height: ${(p) => p.xs};

	${(p) =>
		p.sm &&
		`@media (min-width: ${p.theme.responsive.breakpoints.sm}) {
			height: ${p.sm};
		}`}

	${(p) =>
		p.md &&
		`@media (min-width: ${p.theme.responsive.breakpoints.md}) {
			height: ${p.md};
		}`}

	${(p) =>
		p.lg &&
		`@media (min-width: ${p.theme.responsive.breakpoints.lg}) {
			height: ${p.lg};
		}`}

	${(p) =>
		p.xl &&
		`@media (min-width: ${p.theme.responsive.breakpoints.xl}) {
			height: ${p.xl};
		}`}
`
