import { sendRequest } from '../../util'

/**
 * Logout User
 */
export async function logout(): Promise<boolean> {
	// Make api request
	const result = await sendRequest('post', '/api/auth/logout')

	// Return boolean
	return result.error ? false : true
}
