import { ReactElement } from 'react'
import styled from 'styled-components'

//----- Types -----//

type MessageVariant = 'success' | 'warn' | 'fail' | 'empty'

//----- Styling -----//

const Message = styled.div<{
	variant?: MessageVariant
	center?: boolean
	big?: boolean
	inverted?: boolean
}>`
	margin: ${(p) => (p.big ? '0 0 0.5em' : '4px 0')};
	text-align: right;

	transition: color 300ms ease-in-out;

	font-size: ${(p) => (p.big ? '0.9rem' : '0.65rem')};
	font-weight: ${(p) => p.theme.font.sans.weight.bold};
	letter-spacing: ${(p) => p.theme.font.sans.spacing.slim};
	${(p) => (p.center ? 'text-align: center;' : '')}

	color: ${(p) =>
		p.variant === 'empty' || !p.variant
			? 'transparent'
			: p.theme.color.status[p.variant + (p.inverted ? 'Inverted' : '')]};
`

//----- Component -----//

export const ResultMessage = (props: {
	variant?: MessageVariant
	center?: boolean
	big?: boolean
	inverted?: boolean
	text?: string
}): ReactElement => {
	const { text, ...messageProps } = props
	return <Message {...messageProps}>{text || '\u00A0'}</Message>
}
