import styled from 'styled-components'
import { ReactNode, ReactElement } from 'react'

//----- Styling -----//

const TileWrap = styled.div`
	display: flex;
	flex-direction: column;
	padding: 16px;
	align-items: center;

	@media (min-width: ${(p) => p.theme.responsive.breakpoints.md}) {
		align-items: flex-start;
	}
`

const TitleArea = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;

	@media (min-width: ${(p) => p.theme.responsive.breakpoints.md}) {
		flex-direction: row;
		align-items: flex-end;
	}
`

const Title = styled.div`
	font-size: 1.125em;
	text-transform: uppercase;
	vertical-align: middle;
	line-height: 1;

	@media (min-width: ${(p) => p.theme.responsive.breakpoints.md}) {
		font-size: 1.25em;
	}
	@media (min-width: ${(p) => p.theme.responsive.breakpoints.lg}) {
		font-size: 1.3em;
	}
`

const Subtitle = styled.div`
	text-transform: uppercase;
	vertical-align: middle;
	padding-left: 5px;
	line-height: 1;
`

//----- Component -----//

interface TileProps {
	title: string
	subtitle: string
	children: ReactNode
}

export const Tile = (props: TileProps): ReactElement => {
	return (
		<TileWrap>
			<TitleArea>
				<Title>{props.title}</Title>
				<Subtitle>{props.subtitle}</Subtitle>
			</TitleArea>
			{props.children}
		</TileWrap>
	)
}
