import { SiteConfig } from '~/config'

const { rootUrl } = SiteConfig.siteInfo

//----- Helper Logic -----//

const getYear = (dateString = 'January 1, 1970') =>
	new Date(dateString).getUTCFullYear()

const root = (showRoot: boolean) => (showRoot ? rootUrl : '')

//----- Path Creation Logic -----//

export const getPath = {
	article: (
		articleSlug = 'unknown',
		categorySlug = 'uncategorized',
		fullUrl = false
	): string => `${root(fullUrl)}/${categorySlug}/${articleSlug}`,

	author: (slug = 'unknown', fullUrl = false): string =>
		`${root(fullUrl)}/author/${slug}`,

	blog: (slug = 'unknown', fullUrl = false): string =>
		`${root(fullUrl)}/blog/${slug}`,

	category: (slug = 'uncategorized', fullUrl = false): string =>
		`${root(fullUrl)}/${slug}`,

	customPage: (slug = 'unknown', fullUrl = false): string =>
		`${root(fullUrl)}/${slug}`,

	department: (slug = 'unknown', fullUrl = false): string =>
		`${root(fullUrl)}/department/${slug}`,

	issue: (slug?: string, date?: string, fullUrl = false): string =>
		`${root(fullUrl)}/magazine${date ? `/${getYear(date)}` : ''}${
			slug ? `/${slug}` : ''
		}`,

	reviewer: (slug = 'unknown', fullUrl = false): string =>
		`${root(fullUrl)}/reviewer/${slug}`,

	tag: (slug = 'unknown', fullUrl = false): string =>
		`${root(fullUrl)}/tag/${slug}`
}
