import { analytics } from './analytics'
import { app } from './app'
import { article } from './article'
import { categories } from './categories'
import { externalUrls } from './externalUrls'
import { footerInfo } from './footerInfo'
import { insticator } from './insticator'
import { mail } from './mail'
import { newsletters } from './newsletters'
import { richText } from './richText'
import { siteInfo } from './siteInfo'
import { siteMap } from './siteMap'
import { social } from './social'
import { storeInfo } from './storeInfo'
import { vendor } from './vendor'

export const SiteConfig = Object.freeze({
	analytics,
	app,
	article,
	categories,
	externalUrls,
	footerInfo,
	insticator,
	mail,
	newsletters,
	richText,
	siteInfo,
	siteMap,
	social,
	storeInfo,
	vendor
})
