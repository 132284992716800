import React, { memo, useEffect, useState } from 'react'

import { InstareadFrame } from './styles'
import Script from 'next/script'

const Player: React.FC = () => {
	const [playerLoaded, setPlayerLoaded] = useState(false)

	useEffect(() => {
		if (playerLoaded && window._loadIRPlayer) window._loadIRPlayer()
	}, [playerLoaded])

	return (
		<>
			<Script
				async
				onLoad={() => setPlayerLoaded(true)}
				src={`https://instaread.co/js/instaread.discovermagazine.js?version=${new Date().valueOf()}`}
				type="text/javascript"
			/>
			<div className="instaread-audio-player" style={{ marginTop: '1em' }}>
				<InstareadFrame
					$loaded={playerLoaded}
					allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
					allowFullScreen
					id="instaread_iframe"
					loading="lazy"
					name="instaread_playlist"
					sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-modals"
					title="Audio Article"
				/>
			</div>
		</>
	)
}

export const InstareadPlayer = memo(Player)
