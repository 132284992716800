/**
 * Converts the given string into a slug-friendly format.
 * @params string non-slug, such as "Sir, what are you doing?"
 * @returns string slug-friendly, such as "sir-what-are-you-doing"
 */
export function getStringAsSlug(string: string): string {
	return string
		.toLowerCase()
		.replace(/\s/g, '-')
		.replace(/([^a-z0-9-])/g, '')
}
