import { sendRequest } from '../../util'

/**
 * Login User
 */
export async function login(
	email: string,
	pass: string
): Promise<number | boolean> {
	// Make api request
	const result = await sendRequest('post', '/api/auth/login', { email, pass })

	// Return boolean
	return result.error ? result.status : true
}
