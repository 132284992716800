import {
	createContext,
	ReactNode,
	ReactElement,
	useContext,
	useState,
	useEffect,
	Dispatch,
	SetStateAction
} from 'react'

export interface AppStateData {
	accessCodeModalIsOpen: boolean
	backdropIsOpen: boolean
	loginModalIsOpen: boolean
	paywallPromptIsOpen: boolean
	readerModalIsOpen: boolean
	readerTarget: string | null
	registerModalIsOpen: boolean
	slideoutMenuIsOpen: boolean
}

interface AppStateMethods {
	closeReaderModal: () => void
	openReaderModal: (target: string | null) => void
	setAccessCodeModalOpen: Dispatch<SetStateAction<boolean>>
	setLoginModalOpen: Dispatch<SetStateAction<boolean>>
	setPaywallPromptOpen: Dispatch<SetStateAction<boolean>>
	setRegisterModalOpen: Dispatch<SetStateAction<boolean>>
	toggleAccessCodeModal: () => void
	toggleLoginModal: () => void
	toggleRegisterModal: () => void
	toggleSlideoutMenu: () => void
	closeAllDialogs: () => void
}

interface AppState extends AppStateData, AppStateMethods {}

const AppContext = createContext<AppState | undefined>(undefined)

//----- Consumers -----//

export const useAppContext = (): AppState => useContext(AppContext) as AppState
export const AppConsumer = AppContext.Consumer

//----- Provider -----//

interface AppProps {
	children: ReactNode
}

// TODO: Refactor as a single state object, like UserContext
export const AppProvider = (props: AppProps): ReactElement => {
	const [backdropIsOpen, setBackdropOpen] = useState(false)
	const [accessCodeModalIsOpen, setAccessCodeModalOpen] = useState(false)
	const [loginModalIsOpen, setLoginModalOpen] = useState(false)
	const [paywallPromptIsOpen, setPaywallPromptOpen] = useState(false)
	const [readerModalIsOpen, setReaderModalOpen] = useState(false)
	const [readerTarget, setReaderTarget] = useState<string | null>(null)
	const [registerModalIsOpen, setRegisterModalOpen] = useState(false)
	const [slideoutMenuIsOpen, setSlideoutMenuOpen] = useState(false)

	/**
	 * This will close the reader modal and unset its target
	 */
	function closeReaderModal() {
		setReaderTarget(null)
		setReaderModalOpen(false)
	}

	/**
	 * This will open the reader modal and set its target
	 */
	function openReaderModal(target: string | null) {
		if (!target) return
		setReaderTarget(target)
		setReaderModalOpen(true)
	}

	/**
	 * This will close any modal dialogs that are currently open
	 */
	function closeAllDialogs(): void {
		closeReaderModal()
		setAccessCodeModalOpen(false)
		setLoginModalOpen(false)
		setRegisterModalOpen(false)
		setSlideoutMenuOpen(false)
		// PaywallPrompt does not close unless explicitly closed
	}

	/**
	 * This toggles both the access code modal and the backdrop
	 */
	function toggleAccessCodeModal(): void {
		setAccessCodeModalOpen(!accessCodeModalIsOpen)
	}

	/**
	 * This toggles both the login modal and the backdrop
	 */
	function toggleLoginModal(): void {
		setLoginModalOpen(!loginModalIsOpen)
	}

	/**
	 * This toggles both the register modal and the backdrop
	 */
	function toggleRegisterModal(): void {
		setRegisterModalOpen(!registerModalIsOpen)
	}

	/**
	 * This toggles both the slideout menu and the backdrop
	 */
	function toggleSlideoutMenu(): void {
		setSlideoutMenuOpen(!slideoutMenuIsOpen)
	}

	// Automatically determine whether to show the backdrop
	useEffect(() => {
		const anyDialogIsOpen =
			accessCodeModalIsOpen ||
			loginModalIsOpen ||
			paywallPromptIsOpen ||
			readerModalIsOpen ||
			registerModalIsOpen ||
			slideoutMenuIsOpen

		setBackdropOpen(anyDialogIsOpen)
	}, [
		accessCodeModalIsOpen,
		loginModalIsOpen,
		paywallPromptIsOpen,
		readerModalIsOpen,
		registerModalIsOpen,
		slideoutMenuIsOpen
	])

	return (
		<AppContext.Provider
			value={{
				// Current State
				accessCodeModalIsOpen,
				backdropIsOpen,
				loginModalIsOpen,
				paywallPromptIsOpen,
				readerModalIsOpen,
				readerTarget,
				registerModalIsOpen,
				slideoutMenuIsOpen,
				// Setters
				closeReaderModal,
				openReaderModal,
				setAccessCodeModalOpen,
				setLoginModalOpen,
				setPaywallPromptOpen,
				setRegisterModalOpen,
				// Toggles
				toggleAccessCodeModal,
				toggleLoginModal,
				toggleRegisterModal,
				toggleSlideoutMenu,
				// Misc
				closeAllDialogs
			}}
		>
			{props.children}
		</AppContext.Provider>
	)
}
