import { sendRequest } from '../../util'

/**
 * Newsletter Optins
 */
export async function getNewsletterOptins(): Promise<false | string[]> {
	// Make api request
	const result = await sendRequest<{ code: string; added: string }[]>(
		'get',
		'/api/newsletter/optins'
	)

	// Return false if error ocurred
	if (!result || !result.data || result.error) return []

	// We only care about the optin codes
	return result.data.map((optin) => optin.code)
}
