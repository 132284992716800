import React from 'react'
import styled from 'styled-components'

//----- Styling -----//

const Title = styled.div`
	margin-bottom: 0.25em;
	text-transform: uppercase;
	font-family: ${(p) => p.theme.font.title.family};
	font-size: 1.5em;
	font-weight: ${(p) => p.theme.font.title.weight.regular};
`

//----- Component -----//

export const SidebarTitle: React.FC<{ children: React.ReactNode }> = (
	props
) => {
	return <Title>{props.children}</Title>
}
