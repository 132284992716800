export const font = {
	serif: {
		familyOnly: 'Martel',
		family: '"Martel", serif',
		weight: {
			regular: '400',
			semiBold: '600',
			bold: '700'
		},
		size: {
			normal: 16
		}
	},
	sans: {
		familyOnly: 'Nunito Sans',
		family: '"Nunito Sans", sans-serif',
		weight: {
			regular: '400',
			semiBold: '600',
			bold: '700'
		},
		size: {
			normal: 16
		},
		spacing: {
			slim: '0.05em',
			wide: '0.1em'
		}
	},
	title: {
		familyOnly: 'Fjalla One',
		family: '"Fjalla One", sans-serif',
		weight: {
			regular: '400',
			bold: '500'
		},
		size: {
			normal: 16
		},
		spacing: {
			wide: '0.1em'
		}
	}
}
