import styled from 'styled-components'
import { darken, transparentize as fade } from 'polished'

import { ButtonBase, glow } from './styles'

//----- Styling -----//

export const Button_Bordered = styled(ButtonBase).attrs<
	{ variant?: 'primary' | 'primary-inverted' | 'contrast' },
	{ color: string }
>((p) => ({
	color:
		p.variant === 'contrast'
			? p.theme.color.primary.contrast
			: p.theme.color.primary.accent
}))<{
	variant?: 'primary' | 'primary-inverted' | 'contrast'
}>`
	color: ${(p) =>
		p.variant === 'primary-inverted'
			? p.theme.color.primary.base
			: p.theme.color.primary.contrast};
	background-color: ${(p) =>
		p.variant === 'primary-inverted'
			? p.theme.color.primary.contrast
			: p.theme.color.primary.base};
	border: 3px solid ${(p) => p.color};
	font-weight: ${(p) => p.theme.font.sans.weight.bold};

	&:active,
	&:focus,
	&:hover {
		${(p) => glow(fade(0.8, p.color))};
	}

	&:active {
		background-color: ${(p) => darken(0.1, p.theme.color.primary.base)};
		border-color: ${(p) => p.color};
	}

	&:disabled {
		color: ${(p) => p.theme.color.gray[38]};
		background-color: ${(p) => p.theme.color.gray[94]};
		border-color: ${(p) => p.theme.color.gray[38]};
	}
`
