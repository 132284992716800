import styled from 'styled-components'

export const ButtonBase = styled.button`
	padding: 0.5em 1.5em;

	text-transform: uppercase;

	cursor: pointer;

	transition:
		background-color 300ms ease-in-out,
		border-color 300ms ease-in-out,
		box-shadow 300ms ease-in-out;

	&:hover,
	&:active,
	&:focus {
		outline: 0;
	}

	&:disabled {
		box-shadow: none;
	}
`
