export const social = Object.freeze({
	twitterUser: '@DiscoverMag',
	twitter: 'https://twitter.com/DiscoverMag',
	facebook: 'https://www.facebook.com/DiscoverMag',
	facebookAppID: '2856110197797247',
	instagram: 'https://www.instagram.com/discover.magazine',
	youtube: 'https://www.youtube.com/user/discovermagazine',
	soundcloud: 'https://soundcloud.com/its-only-science-discover-magazine',
	rss: 'https://www.discovermagazine.com/rss'
})
