import styled, { css } from 'styled-components'

const inlineBorder = css`
	border: 1px solid ${(p) => p.theme.color.gray[74]};
	border-right: 0;
`

const baseBorder = css`
	border: 2px solid ${(p) => p.theme.color.primary.contrast};
`

export const Input = styled.input<{
	inline?: boolean
	tall?: boolean
	fullWidth?: boolean
}>`
	${(p) => (p.fullWidth ? 'width: 100%' : 'width: 10px; flex-grow: 1')};

	padding: ${(p) => (p.tall ? '0.5em' : '0.25em 1em')};

	font-size: ${(p) => (p.tall ? '1.2rem' : '1rem')};
	line-height: 1;

	${(p) => (p.inline ? inlineBorder : baseBorder)}

	&::placeholder {
		font-weight: ${(p) => p.theme.font.sans.weight.semiBold};
		text-transform: uppercase;
	}
`
