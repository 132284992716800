import { color } from './color'
import { font } from './font'
import { global, GlobalStyle } from './global'
import { misc } from './misc'
import { responsive } from './responsive'

export const MainTheme = {
	theme: {
		color,
		font,
		global,
		misc,
		responsive
	},
	GlobalStyle
}
