import Head from 'next/head'

const faviconSizes = [
	'16',
	'32',
	'128',
	'152',
	'167',
	'180',
	'192',
	'196',
	'512'
]

function getSizes(size: string): string {
	return `${size}x${size}`
}

export const Favicons: React.FC = () => (
	<Head>
		{faviconSizes.map((size) => (
			<link
				key={`icon-${size}`}
				rel="icon"
				href={`/assets/favicon/favicon${size}.png`}
				sizes={getSizes(size)}
			/>
		))}
	</Head>
)
