import { ReactElement } from 'react'

import { Anchor_Base } from './Anchor_Base'
import { AnchorPropsExternal } from './Anchor_types'

export const Anchor_External = (props: AnchorPropsExternal): ReactElement => {
	const baseRel = 'noopener'
	const sponsoredRel = props.sponsored ? ' sponsored' : ''
	const nofollowRel = props.nofollow ? ' nofollow' : ''
	const rel = `${baseRel}${sponsoredRel}${nofollowRel}`

	return (
		// eslint-disable-next-line react/jsx-no-target-blank -- eslint fails to detect rel
		<Anchor_Base
			onClick={props.onClick}
			href={props.to}
			className={props.className}
			style={props.style}
			color={props.color}
			aria-label={props['aria-label']}
			target="_blank"
			download={props.download}
			rel={rel}
		>
			{props.children}
		</Anchor_Base>
	)
}
