import styled from 'styled-components'

//----- Types -----//

type Align = 'right' | 'center' | 'left' | undefined

interface ListInlineProps {
	align?: Align
	gap?: number
}

//----- Util -----//

function getJustify(align: Align) {
	switch (align) {
		case 'right':
			return 'flex-end'
		case 'center':
			return 'center'
		case 'left':
		default:
			return 'flex-start'
	}
}

//----- Component -----//

export const List_Inline = styled.ul<ListInlineProps>`
	display: flex;
	align-items: center;
	justify-content: ${(props) => getJustify(props.align)};
	margin: 0;
	padding: 0;

	& > li:not(:last-child) {
		margin-right: ${(props) => props.gap || 1}em;
	}
`
