import styled, { css } from 'styled-components'

//----- Definitions -----//

const SIZE = 40
const SPEED = '2s'

//----- Styling -----//

/**
 * If floating === true, the spinner is set to 0 width in order to
 * float next to text content without impacting its position
 */
const SpinnerWrap = styled.div<{ size: number; floating?: boolean }>`
	display: inline-block;
	width: ${(p) => p.size}px;
	height: ${(p) => p.size}px;

	${(p) =>
		p.floating &&
		css`
			width: 0;
			overflow: visible;
			& > div {
				margin-left: 0.5em;
				transform: translateY(2px);
			}
		`}
`

// Original Source: https://loading.io/css/
const Spinner = styled.div<{ size: number; variant?: 'default' | 'base' }>`
	display: inline-block;
	position: relative;
	width: ${(p) => p.size}px;
	height: ${(p) => p.size}px;

	div {
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: ${(p) => p.size * 0.8}px;
		height: ${(p) => p.size * 0.8}px;
		margin: ${(p) => p.size * 0.1}px;
		border: ${(p) => p.size * 0.1}px solid
			${(p) =>
				p.variant === 'base'
					? p.theme.color.primary.base
					: p.theme.color.primary.accent};
		border-radius: 50%;
		animation: loading-ring ${SPEED} cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: ${(p) =>
				p.variant === 'base'
					? p.theme.color.primary.base
					: p.theme.color.primary.accent}
			transparent transparent transparent;

		&:nth-child(1) {
			animation-delay: -0.45s;
		}
		&:nth-child(2) {
			animation-delay: -0.3s;
		}
		&:nth-child(3) {
			animation-delay: -0.15s;
		}
	}
	@keyframes loading-ring {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`

//----- Component -----//

export const LoadingSpinner_Ring: React.FC<{
	size?: number
	variant?: 'default' | 'base'
	floating?: boolean
	className?: string
}> = ({ size = SIZE, variant = 'default', floating = false, className }) => (
	<SpinnerWrap size={size} floating={floating} className={className}>
		<Spinner size={size} variant={variant}>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</Spinner>
	</SpinnerWrap>
)
