import React, {
	useState,
	useRef,
	useEffect,
	ReactNode,
	ReactElement
} from 'react'
import styled from 'styled-components'

//----- Styling -----//

const Outer = styled.div`
	position: relative;
	height: 100%;
`

// Prevent rendering artifacts due to 3D accelleration
const Inner = styled.div`
	transform: translateZ(0);
`

//----- Logic -----//

type AbsolutePosition = {
	position: 'absolute'
	top: 'auto'
	left: number
	right: number
	bottom: number
}
type FixedPosition = {
	position: 'fixed'
	left: number
	width: number
	top: number
}
type RelativePosition = {
	position: undefined
	width: number
}

type Position = AbsolutePosition | FixedPosition | RelativePosition

function getPositions(
	containerRect: DOMRect,
	top: number
): {
	relative: RelativePosition
	fixed: FixedPosition
	absolute: AbsolutePosition
} {
	return {
		relative: {
			position: undefined,
			width: containerRect.width
		},
		fixed: {
			position: 'fixed',
			left: containerRect.left,
			width: containerRect.width,
			top
		},
		absolute: {
			position: 'absolute',
			top: 'auto',
			left: 0,
			right: 0,
			bottom: 0
		}
	}
}

function updatePosition(
	innerNode: HTMLDivElement | null,
	outerNode: HTMLDivElement | null,
	topOffset: number,
	callback: React.Dispatch<Position>
) {
	if (!outerNode || !innerNode) return

	const outerRect = outerNode.getBoundingClientRect()
	const innerRect = innerNode.getBoundingClientRect()

	const position = getPositions(outerRect, topOffset)

	// Is the article's content smaller than the sidebar's content?
	if (outerRect.height <= innerRect.height) return callback(position.relative)

	// Has the user scrolled to the point where the sidebar would overflow the footer?
	if (outerRect.bottom - topOffset <= innerRect.height)
		return callback(position.absolute)

	// Has the user scrolled past the point of sticky behavior?
	if (outerRect.top < topOffset) return callback(position.fixed)

	return callback(position.relative)
}

//----- Component -----//

type StickySidebarProps = {
	topOffset?: number
	children: ReactNode
}

export function StickySidebar(props: StickySidebarProps): ReactElement {
	const [stickyPosition, setStickyPosition] = useState<Position>()

	const { topOffset = 160 } = props

	const outerRef = useRef<HTMLDivElement>(null)
	const innerRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		const triggerUpdate = () => {
			updatePosition(
				innerRef.current,
				outerRef.current,
				topOffset,
				setStickyPosition
			)
		}

		window.addEventListener('scroll', triggerUpdate)
		window.addEventListener('resize', triggerUpdate)

		triggerUpdate()

		return () => {
			window.removeEventListener('scroll', triggerUpdate)
			window.removeEventListener('resize', triggerUpdate)
		}
	}, [topOffset])

	return (
		<Outer ref={outerRef}>
			<Inner ref={innerRef} style={stickyPosition}>
				{props.children}
			</Inner>
		</Outer>
	)
}
