import { SiteConfig } from '~/config'

/**
 * Determines whether the current window width exceeds the minimum required to be considered "desktop"
 */
export function getShouldBeDesktop(): boolean {
	const currentWidth = Math.max(
		document.documentElement.clientWidth,
		window.innerWidth || 0
	)

	return currentWidth >= SiteConfig.app.deviceDesktopMinWidth
}
