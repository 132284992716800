type PageType = 'article' | 'home' | 'listing'

function addCustomSlot(slotName: string, slotSize = [970, 90]): void {
	if (!window.blogherads.adq) window.blogherads.adq = []

	try {
		window.blogherads.adq.push(() => {
			window.blogherads.adq.push(() => {
				window.blogherads.defineCustomSlot(slotName, slotSize).display()
			})
		})
	} catch (e) {
		console.error('Unhandled SHE Media error caught!')
	}
}

function initialize(pageType?: PageType): void {
	if (!window?.blogherads) return
	if (!window.blogherads.adq) window.blogherads.adq = []

	try {
		// For Desktop Adhesion Only
		window.blogherads.adq.push(() => {
			window.blogherads.adq.push('frame2')
		})

		// For Mobile Adhesion Only
		window.blogherads.adq.push(() => {
			window.blogherads.adq.push('bottom')
		})
	} catch (e) {
		console.error('Unhandled SHE Media error caught!')
	}

	// Add custom slots based on page type
	switch (pageType) {
		case 'article':
			addCustomSlot('articlePageTopDesktop')
			return

		case 'home':
			addCustomSlot('homepageBottomDesktop')
			addCustomSlot('homepageBillboardDesktop')
			return

		case 'listing':
			addCustomSlot('listingPageTopDesktop')
			return

		default:
	}
}

export const BlogHerAds = { addCustomSlot, initialize }
