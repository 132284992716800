import { createContext, ReactNode, ReactElement, useContext } from 'react'

export interface PageStateData {
	announce: {
		text?: string
		link?: string
	}
	title?: string
	path?: string
	slug?: string
	adTargetingId?: string | null
}

// Note: Remove this comment when PageStateMethods is popluated
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PageStateMethods {}
interface PageState extends PageStateData, PageStateMethods {}

const PageContext = createContext<PageState>({ announce: {} })

//----- Consumers -----//

export const usePageContext = (): PageState => useContext(PageContext)
export const PageConsumer = PageContext.Consumer

//----- Provider -----//

interface PageProps {
	pageConfig: PageState
	children: ReactNode
}

export const PageProvider = (props: PageProps): ReactElement => (
	<PageContext.Provider value={props.pageConfig}>
		{props.children}
	</PageContext.Provider>
)
