import Head from 'next/head'
import React from 'react'
import type { Organization, WithContext } from 'schema-dts'
import { SiteConfig } from '~/config'

export const OrganizationSchema: React.FC = () => {
	const schema: WithContext<Organization> = {
		'@context': 'https://schema.org',
		'@type': 'Organization',
		name: SiteConfig.siteInfo.title,
		url: SiteConfig.siteInfo.rootUrl,
		logo: { '@type': 'ImageObject', ...SiteConfig.siteInfo.logoImage },
		sameAs: [
			'https://www.facebook.com/DiscoverMag',
			'https://twitter.com/discovermag',
			'https://www.instagram.com/discover.magazine/',
			'https://www.youtube.com/user/discovermagazine',
			'https://soundcloud.com/its-only-science-discover-magazine'
		]
	}

	return (
		<Head>
			<script
				key="organization-schema"
				type="application/ld+json"
				dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
			></script>
		</Head>
	)
}
