import type { Category } from '~/types/category'

type InsticatorConfig = Record<Category, string>

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error -- TODO: This will throw a TS error until the other categories are uncommented
export const insticator: InsticatorConfig = Object.freeze({
	'the-sciences': '4b651d8f-ea11-445f-9ea7-e776ca36eef3',
	mind: '96d745d8-9e22-4359-b211-4752ea4d8873',
	health: '9b6b7608-0101-4c26-8de9-6aad26a6d2b9',
	environment: '9d18565e-af4d-4ddd-bfc5-2dbf51c5675e',
	technology: 'cade7ffa-0563-48de-839f-f84e596c0b94',
	'planet-earth': 'dcfb67aa-fdf4-4a19-b4c7-75c497a8de9c'
})
