import { externalUrls } from './externalUrls'

export const footerInfo = Object.freeze({
	copyrightUrl: 'https://www.discovermagazine.com/',
	accessibilityUrl: 'https://www.discovermagazine.com/',
	linkList: Object.freeze([
		Object.freeze({
			behavior: 'none',
			text: 'The Magazine',
			url: '/magazine'
		}),
		Object.freeze({
			behavior: 'none',
			text: 'About Discover',
			url: '/about'
		}),
		Object.freeze({
			behavior: 'none',
			text: 'Privacy Policy',
			url: 'https://www.discovermagazine.com/'
		}),
		Object.freeze({
			behavior: 'trackSubscribeClick',
			text: 'Subscribe',
			url: externalUrls.subscribe
		}),
		Object.freeze({
			behavior: 'none',
			text: 'Advertise',
			url: '/advertise'
		}),
		Object.freeze({
			behavior: 'none',
			text: 'Newsletter',
			url: '/newsletter-signup'
		}),
		Object.freeze({
			behavior: 'none',
			text: 'Terms of Use',
			url: 'https://www.discovermagazine.com/'
		}),
		Object.freeze({
			behavior: 'none',
			text: 'Customer Service',
			url: 'https://kalmbach.dragonforms.com/loading.do?omedasite=DSC_custserv'
		}),
		Object.freeze({
			behavior: 'none',
			text: 'Contact',
			url: '/contact-us'
		}),
		Object.freeze({
			behavior: 'none',
			text: 'Copyright Policy',
			url: 'https://www.discovermagazine.com/'
		})
	]),
	socialList: Object.freeze([
		Object.freeze({
			image: 'facebook',
			altName: 'Facebook',
			url: 'https://www.facebook.com/DiscoverMag'
		}),
		Object.freeze({
			image: 'instagram',
			altName: 'Instagram',
			url: 'https://www.instagram.com/discover.magazine'
		}),
		Object.freeze({
			image: 'twitter',
			altName: 'Twitter',
			url: 'https://twitter.com/discovermag'
		}),
		Object.freeze({
			image: 'youtube',
			altName: 'YouTube',
			url: 'https://www.youtube.com/user/discovermagazine'
		}),
		Object.freeze({
			image: 'soundcloud',
			altName: 'Soundcloud',
			url: 'https://soundcloud.com/its-only-science-discover-magazine'
		}),
		Object.freeze({
			image: 'rss',
			altName: 'RSS',
			url: '/rss'
		})
	])
})
