import styled from 'styled-components'

/**
 * Used to provide an invisible offset point for anchor targets
 */
export const AnchorOffset = styled.div`
	&::before {
		content: '';
		display: block;
		height: 150px;
		margin-top: -150px;
		visibility: hidden;
		pointer-events: none;
	}
`
