import {
	createContext,
	ReactNode,
	ReactElement,
	useContext,
	useState
} from 'react'

export interface UserStateData {
	// Flags
	isLoggedIn: boolean
	hasMagazineSub: boolean
	hasNewsletterSub: boolean
	// Info
	firstName?: string
	lastName?: string
	email?: string
	password?: string
	// Etc.
	newsletterSelections: string[]
}

interface UserStateMethods {
	// Bulk Changes
	reset: () => void
	hydrate: (data: Partial<UserStateData>, preserveExisting?: boolean) => void
	// Flags
	setIsLoggedIn: (loggedIn: boolean) => void
	setHasMagazineSub: (hasSub: boolean) => void
	setHasNewsletterSub: (hasSub: boolean) => void
	// Strings
	setName: (firstName: string, lastName: string) => void
	setFirstName: (firstName: string) => void
	setLastName: (lastName: string) => void
	setEmail: (email: string) => void
	setPassword: (password: string) => void
	// Etc
	setNewsletterSelections: (selections: string[]) => void
}

export interface UserState extends UserStateData, UserStateMethods {}

const UserContext = createContext<UserState | undefined>(undefined)

//----- Consumers -----//

export const useUserContext = (): UserState =>
	useContext(UserContext) as UserState

export const UserConsumer = UserContext.Consumer

//----- Provider -----//

interface UserProps {
	children: ReactNode
}

export const UserProvider = (props: UserProps): ReactElement => {
	const [userData, setUserData] = useState<UserStateData>({
		// Flags
		isLoggedIn: false,
		hasMagazineSub: false,
		hasNewsletterSub: false,
		// Etc.
		newsletterSelections: []
	})

	//----- Field Changes -----//

	// Flags
	const setIsLoggedIn = (isLoggedIn: UserStateData['isLoggedIn']) =>
		setUserData({ ...userData, isLoggedIn })

	const setHasMagazineSub = (hasMagazineSub: UserStateData['hasMagazineSub']) =>
		setUserData({ ...userData, hasMagazineSub })

	const setHasNewsletterSub = (
		hasNewsletterSub: UserStateData['hasNewsletterSub']
	) => setUserData({ ...userData, hasNewsletterSub })

	// Strings
	const setFirstName = (firstName: UserStateData['firstName']) =>
		setUserData({ ...userData, firstName })

	const setLastName = (lastName: UserStateData['lastName']) =>
		setUserData({ ...userData, lastName })

	const setEmail = (email: UserStateData['email']) =>
		setUserData({ ...userData, email })

	const setPassword = (password: UserStateData['password']) =>
		setUserData({ ...userData, password })

	// Etc.
	const setNewsletterSelections = (
		newsletterSelections: UserStateData['newsletterSelections']
	) => setUserData({ ...userData, newsletterSelections })

	//----- Bulk Changes -----//

	/**
	 * Set the user's full name.
	 * Note: Deprecated in favor of hydrate
	 */
	function setName(firstName?: string, lastName?: string) {
		setUserData({ ...userData, ...{ firstName, lastName } })
	}

	/**
	 * Set all fields simultaneously
	 */
	function hydrate(
		newUserData: Partial<UserStateData>,
		preserveExisting?: boolean
	) {
		const defaults: UserStateData = {
			isLoggedIn: false,
			hasMagazineSub: false,
			hasNewsletterSub: false,
			newsletterSelections: []
		}

		setUserData(
			preserveExisting
				? {
						...defaults,
						...userData,
						...newUserData
				  }
				: {
						...defaults,
						...newUserData
				  }
		)
	}

	/**
	 * Set all user properties to their default anonymous values
	 */
	function reset() {
		setUserData({
			// Flags
			isLoggedIn: false,
			hasMagazineSub: false,
			hasNewsletterSub: false,
			// Strings
			firstName: undefined,
			lastName: undefined,
			email: undefined,
			password: undefined,
			// Etc.
			newsletterSelections: []
		})
	}

	return (
		<UserContext.Provider
			value={{
				...userData,
				// Bulk Changes
				hydrate,
				reset,
				// Flags
				setIsLoggedIn,
				setHasMagazineSub,
				setHasNewsletterSub,
				// Strings
				setName,
				setFirstName,
				setLastName,
				setEmail,
				setPassword,
				// Etc.
				setNewsletterSelections
			}}
		>
			{props.children}
		</UserContext.Provider>
	)
}
