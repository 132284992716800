import { ReactElement } from 'react'
import LazyLoad from 'react-lazyload'
import Head from 'next/head'
import styled from 'styled-components'

//----- Styling -----//

const Img = styled.img<{ maxHeight?: number; maxWidth?: number }>`
	width: 100%;
	height: auto;
	max-width: ${(p) => (p.maxWidth ? `${p.maxWidth}px` : '100%')};
	${(p) => p.maxHeight && `max-height: ${p.maxHeight}px;`}
`

//----- Component -----//

export const Image = (props: {
	src: string
	alt: string
	eager?: boolean
	preload?: boolean
	maxWidth?: number
	maxHeight?: number
	className?: string
}): ReactElement => {
	const { eager, preload, ...imageProps } = props

	// Setting these values prevents cumulative layout shift.
	// The values are overridden in the stylesheet to keep images responsive.
	const baseDimensions = {
		height: props.maxWidth ? `${props.maxWidth}px` : undefined,
		width: props.maxHeight ? `${props.maxHeight}px` : undefined
	}

	return (
		<>
			{preload && (
				<Head>
					<link key={props.src} rel="preload" as="image" href={props.src} />
				</Head>
			)}
			{eager ? (
				<Img {...imageProps} {...baseDimensions} />
			) : (
				// Use maxHeight to reduce cumulative layout shift impact
				<LazyLoad once height={props.maxHeight}>
					<Img {...imageProps} {...baseDimensions} />
				</LazyLoad>
			)}
		</>
	)
}
