const brandPalette = {
	green: {
		dark: 'hsl(83, 29%, 38%)', // Print: 'hsl(83, 29%, 47%)'
		light: 'hsl(82, 26%, 70%)'
	},
	orange: {
		dark: 'hsl(35, 59%, 38%)', // Print: hsl(35, 59%, 54%)'
		light: 'hsl(46, 71%, 58%)'
	},
	teal: {
		dark: 'hsl(186, 39%, 38%)', // Print: 'hsl(186, 39%, 41%)'
		light: 'hsl(185, 43%, 64%)'
	},
	red: {
		dark: 'hsl(4, 68%, 52%)', // Print: 'hsl(4, 68%, 57%)'
		light: 'hsl(4, 68%, 68%)'
	},
	gray: {
		0: 'hsl(0, 0%, 0%)',
		45: 'hsl(192, 2%, 45%)',
		70: 'hsl(84, 3%, 70%)',
		100: 'hsl(0, 0%, 100%)'
	}
}

export const color = {
	primary: {
		base: brandPalette.gray[100],
		contrast: brandPalette.gray[0],
		accent: brandPalette.red.dark
	},
	secondary: {
		base: brandPalette.orange.light,
		contrast: brandPalette.gray[0],
		accent: brandPalette.teal.dark
	},
	tertiary: {
		base: brandPalette.gray[0],
		contrast: brandPalette.gray[100],
		accent: brandPalette.orange.light
	},
	gray: {
		0: 'hsl(0, 0%, 0%)', // #000000
		13: 'hsl(0, 0%, 13%)', // #212121
		26: 'hsl(0, 0%, 26%)', // #424242
		38: 'hsl(0, 0%, 38%)', // #616161
		46: 'hsl(0, 0%, 46%)', // #767676
		67: 'hsl(0, 0%, 67%)', // #aaaaaa
		74: 'hsl(0, 0%, 74%)', // #bdbdbd
		84: 'hsl(0, 0%, 84%)', // #d5d5d5
		90: 'hsl(0, 0%, 90%)', // #e6e6e6
		94: 'hsl(0, 0%, 94%)', // #f0f0f0
		100: 'hsl(0, 0%, 100%)' // #ffffff
	},
	gradients: {
		diagonalGray:
			'repeating-linear-gradient(-45deg, rgba(0, 0, 0, 0) 0px, #bdbdbd 1px, #bdbdbd 1px, rgba(0, 0, 0, 0) 2px, rgba(0, 0, 0, 0) 3px)'
	},
	status: {
		success: brandPalette.green.dark,
		successInverted: brandPalette.green.light,
		warn: brandPalette.orange.dark,
		warnInverted: brandPalette.orange.light,
		fail: brandPalette.red.dark,
		failInverted: brandPalette.red.light
	},
	category: {
		health: 'hsl(204, 70%, 42%)',
		mind: 'hsl(30, 100%, 35%)',
		technology: 'hsl(78, 75%, 29%)',
		'the-sciences': 'hsl(209, 62%, 32%)',
		'planet-earth': 'hsl(156, 40%, 32%)',
		environment: 'hsl(24, 49%, 46%)',
		podcasts: 'hsl(195, 47%, 36%)',
		default: brandPalette.red.dark
	}
}
