//----- HTTP Status Codes -----//

export const httpStatus = Object.freeze({
	SUCCESS: 200,
	SUCCESS_EMPTY: 204,
	REDIRECT_PERM: 301,
	REDIRECT_TEMP: 302,
	ERR_USER: 400, // Generic user-side error
	ERR_NOAUTH: 401, // User is not authenticated
	ERR_NOACCESS: 403, // User is not allowed to access
	ERR_MISSING: 404, // Requested content does not exist
	ERR_SERVER: 500, // Generic server-side error
	ERR_TIMEOUT: 504 // Request timeout (i.e. 3rd party service unavailable)
})
