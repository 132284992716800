import type { ListPageType } from '~/types/app'
import type { ArticleSummaryData } from '~/types/content'
import type { ListingPageKservResult } from '~/types/page'

import { sendRequest } from '../util'

/**
 * Fetches content from the specified listing page endpoint
 */
export async function getListingPage(
	listType: ListPageType,
	slug = '',
	pageNum = 1
): Promise<ArticleSummaryData[] | false> {
	if (!slug) return false

	const result = await sendRequest<ListingPageKservResult>(
		'get',
		`/api/page/listing/${listType}/${slug}/${pageNum}`
	)

	if (result.status !== 200 || !result.data) return false

	return result.data.content.articles
}
